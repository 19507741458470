<script>
export default {
  data() {
    return {};
  },
  async mounted() {
    await this.$A.GetCurAccountData();
  }
};
</script>
<template>
  <div>
    <navigation />
    <div class="row" style="margin: 200px 0">
      <div class="col-md-4 m-auto text-center">
        <img
          class="coreteam-graphic"
          src="/img/ashore-coreteam-graphic.svg"
        />
        <h2 class="mt-25">Welcome Aboard!</h2>
        <p class="mt-25">
          Thank you for joining our crew. Our mission is to create the world's
          most powerful proofing software, and you are making it possible. Feel
          free to reach out if you have any questions, and enjoy your
          subscription!
        </p>
        <a
          href="/create"
          class="mt-25 button button-light button-green normal-button pageheader-button"
        >
          Create a Proof
        </a>
        <a
          class="mt-25 button button-light button-purple normal-button pageheader-button"
          href="https://ashoreapp.com/request-training-session/"
          target="_blank"
        >
          Schedule Training
        </a>
      </div>
    </div>
<!--    <copyright />-->
  </div>
</template>

<style scoped>
.coreteam-graphic {
  height: 400px;
  z-index: 999;
}
</style>
