<template>
  <div class="nav-margin">
    <navigation></navigation>
    <nonPremiumPreflightScreen v-if="!isPremiumAccount" />
    <div v-if="isPremiumAccount" class="d-flex flex-fill flex-lg-row flex-column">
      <div
        class="px-3 py-3 d-flex d-lg-none align-items-center gap-3 justify-content-between border-bottom border-gray-200 bg-white">
        <h6 class="text-gray-900 fw-bold mb-0">Preflights</h6>
        <div class="flex items-center gap-2">
          <button class="text-decoration-none btn btn-link p-0" @click="sidebarToggle()">
            <img src="img/review/icons/chevron-down.svg" width="30" alt="down-arrow">
          </button>
        </div>
      </div>
      <preflightSidebar ref="preflightSidebar" :unsavedFiles="unsavedFiles" :showSidebarDrawer="showSidebarDrawer"
        @renderNewPreflight="renderNewPreflight" @updatePreflightSettings="updatePreflightSettings"
        @showUnsavedFileInfo="showUnsavedFileInfo" @toggleSidebar="sidebarToggle" />
      <preflightUploadArea ref="fileUploadSection" @addNewFile="addNewFile" @unsavedFiles="handleUnsavedFiles"
        @updateUnsavedFiles="updateUnsavedFiles" @deleteFile="deleteFile" />
    </div>
  </div>
</template>

<script>
import preflightSidebar from "../components/preflight-archive/preflightSidebar.vue";
import preflightUploadArea from "../components/preflight-archive/preflightUploadArea.vue";
import nonPremiumPreflightScreen from "../components/preflight-archive/nonPremiumPreflightScreen.vue";

export default {
  components: {
    preflightSidebar,
    preflightUploadArea,
    nonPremiumPreflightScreen
  },
  data() {
    return {
      isPremiumAccount: false,
      currentUnsavedFileId: null,
      unsavedFiles: [],
      showSidebarDrawer: false
    }
  },
  methods: {
    renderNewPreflight() {
      this.$refs.fileUploadSection.removeFile();
    },
    async updatePreflightSettings(file) {
      this.currentUnsavedFileId = null;
      await this.$refs.fileUploadSection.removeFile();
      await this.$refs.fileUploadSection.updateFile(file);
    },
    showUnsavedFileInfo(unsavedFile) {
      this.currentUnsavedFileId = unsavedFile.fileId;
      this.$refs.fileUploadSection.removeFile();
      this.$refs.fileUploadSection.extractPdfDimensions(unsavedFile.file, true);
    },
    addNewFile() {
      if (this.currentUnsavedFileId) {
        this.unsavedFiles = this.unsavedFiles.filter(file => file.fileId !== this.currentUnsavedFileId);
      }
      this.$refs.preflightSidebar.addNewPreflight();
    },
    handleUnsavedFiles(files) {
      const filesArray = Array.from(files);
      const updatedFiles = filesArray.map(file => ({
        file,
        fileId: this.$A.GetUUID()
      }));
      const firstFileId = updatedFiles[0].fileId;
      this.currentUnsavedFileId = firstFileId;
      this.unsavedFiles.push(...updatedFiles)
      this.$refs.preflightSidebar.selectFile(firstFileId);
    },
    updateUnsavedFiles(file) {
      if (this.currentUnsavedFileId) {
        this.unsavedFiles = this.unsavedFiles.filter(file => file.fileId !== this.currentUnsavedFileId);
        this.$refs.preflightSidebar.addFileToToday(file);
      }
    },
    deleteFile(file) {
      this.$refs.preflightSidebar.deleteFile(file);
    },
    sidebarToggle() {
      this.showSidebarDrawer = !this.showSidebarDrawer;
    }
  },
  mounted() {
    this.isPremiumAccount = _.get(this.$A, 'Auth.account.subscriptionState.sku', []).includes('enterprise') || _.get(this.$A, 'Auth.account.subscriptionState.sku', []).includes('premium');
  }
};
</script>

<style scoped>
.preflight-page {
  display: flex;
}
</style>
