<script>
import Multiselect from 'vue-multiselect'

export default {
  name: "SubscriptionCancellationModal",
  props: {
    eventToEmitOnConfirmation: {
      required: false,
      type: String,
    },
    question: {
      required: true,
      type: String,
    }
  },
  components: {Multiselect},
  data() {
    return {
      name: "cancelCurrentSubscriptionModal",
      cancelSubscriptionOptions: [
        'Missing Features',
        'Business Downturn',
        'Pricing',
        'Bugs or Issues',
        'Other',
      ],
      cancelSubscriptionOptionSelected: [],
      cancelSubscriptionOptionEdited: false,
      cancelSubscriptionOptionValid: true,
      feedbackValid: true,
      featureList: [
        'Word Document Proofing',
        'Adobe Plugins',
        'Real-time Collaboration',
        'Manage Files in Proof Version',
        'More Zapier Zaps',
        'Art Intake Forms',
        'Reviewer Logins',
        'SSO',
        'Additional Reporting',
        'Additional Translations',
        'Additional Integrations'
      ],
      selectedFeatures: [],
      feedback: "",
      reasonLabel: "What made you decide to cancel your subscription today?",
      featuresLabel: "Is there a specific feature you’d like to see?",
      feedbackLabel: "Do you have anything else you'd like us to know?",
      optionsPlaceholder: "Select one or many",
    }
  },
  computed: {},
  watch: {
    cancelSubscriptionOptionSelected() {
      this.cancelSubscriptionOptionValid = this.cancelSubscriptionOptionSelected.length > 0;
      this.checkFeedback();
    },
    feedback() {
      this.checkFeedback();
    }
  },
  methods: {
    openModal() {
      this.open();
    },
    open() {
      this.cancelSubscriptionOptionSelected = [];
      this.selectedFeatures = [];
      this.feedback = "";
      this.cancelSubscriptionOptionValid = true;
      this.feedbackValid = true;
      this.cancelSubscriptionOptionEdited = false;

      this.$bvModal.show(this.name);
    },
    setCancelSubscriptionOptionEdited() {
      this.cancelSubscriptionOptionEdited = true
    },
    modalConfirmed() {
      this.cancelSubscriptionOptionEdited = true;

      if (!this.cancelSubscriptionOptionValid || !this.feedbackValid) {
        return;
      }

      let emitData = {
        reason: this.cancelSubscriptionOptionSelected,
        features: this.selectedFeatures,
        feedback: this.feedback,
      }
      if (this.eventToEmitOnConfirmation !== undefined)
        this.$root.$emit(this.eventToEmitOnConfirmation, emitData);
      this.$emit("confirmed", emitData);
      this.$bvModal.hide(this.name);
    },
    checkFeedback() {
      let hasOther = this.cancelSubscriptionOptionSelected.length === 1 && this.cancelSubscriptionOptionSelected[0] === "Other";
      this.feedbackValid = hasOther ? this.feedback !== "" : true;
    },
  },
  mounted() {
  },
};
</script>

<template>
  <div>
    <b-modal :id="name" size="lg">
      <template v-slot:modal-header>
        <alertHeader/>
        <div class="w-100">
          <h4>Are you sure you want to cancel your Enterprise & API subscription?</h4>
          <!-- Cancel Reason -->
          <div class="mt-4">
            <div class="mb-1">
              {{ reasonLabel }}
              <b-badge variant="danger" v-show="cancelSubscriptionOptionEdited && !cancelSubscriptionOptionValid">
                Required
              </b-badge>
            </div>
            <multiselect
                v-model="cancelSubscriptionOptionSelected"
                :options="cancelSubscriptionOptions"
                :placeholder="optionsPlaceholder"
                :multiple="true"
                :show-labels="false"
                :hide-selected="true"
                :clear-on-select="true"
                :searchable="true"
                @close="setCancelSubscriptionOptionEdited"
                :class="cancelSubscriptionOptionEdited && !cancelSubscriptionOptionValid ? 'red-border' : 'green-border'"
            />
            <div v-show=" cancelSubscriptionOptionSelected === ''"
                 class="invalid-feedback">
              Please provide a cancellation reason.
            </div>
          </div>
          <!-- Feature List -->
          <div class="mt-4">
            <div class="mb-1">
              {{ featuresLabel }}
            </div>
            <multiselect
                v-model="selectedFeatures"
                :options="featureList"
                :placeholder="optionsPlaceholder"
                :multiple="true"
                :show-labels="false"
                :allow-empty="true"
                :hide-selected="true"
                :searchable="true"
                class="green-border"
            />
          </div>
          <!--  Additional Details -->
          <div class="mt-4">
            <div class="mb-1">
              {{ feedbackLabel }}
              <b-badge variant="danger" v-show="cancelSubscriptionOptionEdited && !feedbackValid">Required</b-badge>
            </div>
            <b-form-textarea
                id="textarea"
                v-model="feedback"
                placeholder="Your message..."
                rows="3"
                max-rows="6"
                :class="cancelSubscriptionOptionEdited && !feedbackValid ? 'feedback-text-red' : 'feedback-text-green'"
                :autofocus="false"
            ></b-form-textarea>
          </div>
        </div>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <div class="w-100 col-md-12 d-flex justify-content-md-end px-0 footer-buttons">
          <b-button
              class="gray-btn"
              @click="cancel()"
          >Cancel
          </b-button
          >
          <b-button
              class="red-btn"
              @click="modalConfirmed()"
          >Cancel Subscription
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.multiselect__option, .multiselect__input {
  font-size: 14px;
}

.feedback-text-green {
  border: 1px solid #019255;
  font-weight: lighter;
  color: #212529;
}

.feedback-text-red {
  border: 1px solid #dc3545;
  font-weight: lighter;
  color: #212529;
}

textarea.form-control {
  padding: 10px;
}

.red-border > .multiselect__tags {
  border: 1px #dc3545 solid !important;
}

.green-border > .multiselect__tags {
  border: 1px solid #019255 !important;
}

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
}

.red-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 9px 17px;
  height: 36px;
  background: #D44D68;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  padding: 9px 17px !important;
  margin-left: 10px;
}

.red-btn:hover {
  cursor: pointer;
  opacity: 0.9;
}

.gray-btn:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
  cursor: pointer;
}

@media screen and (max-width: 1180px) {
  .footer-buttons {
    justify-content: end;
    margin-bottom: 0px !important;
    padding: 0px;
    align-items: center;
  }

}

</style>