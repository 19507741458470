<script>
import moment from "moment-timezone";
import { getAccountLogo } from "../utils/helpers";
import { SSO_PROVIDERS } from "../utils/constants";
import { parsePhoneNumberFromString, isValidPhoneNumber, AsYouType } from 'libphonenumber-js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

const { MICROSOFT, GOOGLE } = SSO_PROVIDERS;

export default {
  components: {
    VuePhoneNumberInput
  },
  data() {
    return {
      newAccount: {
        organization: "Ashore",
        name: "",
        email: "",
        title: "Your Title",
        phone: "",
        password: "",
        password_confirm: "",
        use_terms_accepted: false,
        data_terms_accepted: false,
      },
      errors: [],
      errorMessage: "",
      variant: "warning",
      validated: false,
      password_match: false,
      submited: false,
      working: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      signupH1: "",
      signupHeadline: "Create Your Free Ashore Account",
      codePlaceHolder: "Your Code",
      isCodeSignup: false,
      affiliate: 1,
      code: "",
      processing: false,
      phoneData: null,
      countryCode: "US", // Default country code
      selectedCountry: "+1",
    };
  },
  methods: {
    updatePhoneNumber(phoneData) {
      this.phoneData = phoneData;
      
      // Use the entire phone data object from the component
      if (phoneData) {
        this.countryCode = phoneData.countryCode || 'US';
        this.selectedCountry = phoneData.countryCallingCode ? '+' + phoneData.countryCallingCode : '+1';
        
        // Store full international format for the API
        if (phoneData.formatInternational) {
          this.newAccount.phone = phoneData.formatInternational;
        } else if (phoneData.phoneNumber) {
          this.newAccount.phone = `${this.selectedCountry} ${phoneData.phoneNumber}`;
        }
        
        if (this.validated) {
          this.checkForm();
        }
      }
    },
    
    formatPhoneForAPI(phone) {
      if (!phone) return "";
      
      try {
        // Parse the phone number with libphonenumber-js for proper E.164 formatting
        const parsedNumber = parsePhoneNumberFromString(phone, this.countryCode);
        if (parsedNumber) {
          return parsedNumber.format('E.164'); // Returns format: +15125551000
        }
      } catch (e) {
        console.error("Error formatting phone number:", e);
      }
      
      // Fallback - just concatenate country code with digits
      const phoneDigits = phone.replace(/\D/g, '');
      return this.selectedCountry + phoneDigits;
    },
    
    validatePhoneNumber: function(phone) {
      if (!phone || phone.trim() === '') {
        return false;
      }
      
      if (this.phoneData && this.phoneData.isValid) {
        return this.phoneData.isValid;
      }
      
      try {
        // Use libphonenumber-js to validate the phone number for the selected country
        return isValidPhoneNumber(phone, this.countryCode);
      } catch (e) {
        console.error("Error validating phone number:", e);
        // Fallback validation - ensure there are enough digits (varies by country)
        const digits = phone.replace(/\D/g, '');
        return digits.length >= 8; // Most countries require at least 8 digits
      }
    },
    ssoClicked: function(ssoMethod){
      window.$A.MarketingService.SSOPreSignupEvent();
      const anchor = document.createElement('a');
      anchor.target = '_blank';
      anchor.innerText = 'SSO';
      const currentDomain = window.location.hostname;
      if(ssoMethod === GOOGLE){
        anchor.href = `${window.$A.Env.apiHost}/login/sso/${GOOGLE}?domain=${currentDomain}`;
      }

      if(ssoMethod === MICROSOFT){
        anchor.href = `${window.$A.Env.apiHost}/login/sso/${MICROSOFT}?domain=${currentDomain}`;
      }
      document.body.appendChild(anchor);
      anchor.click();
    },
    getParamsAsObject: function() {
      const urlParams = new URLSearchParams(window.location.search);
      const paramsObject = {};
      for (const [key, value] of urlParams.entries()) {
        paramsObject[key] = value;
      }
      return paramsObject;
    },
    resetSubmitted: function () {
      let self = this;
      setTimeout(() => {
        self.submited = false;
      }, 5000)
    },
    SignUp() {
      this.checkForm();
      if (!this.allFormElementsValid() || 
          this.password_match.length === 0 || 
          !this.validatePhoneNumber(this.newAccount.phone)) {
        this.showDismissibleAlert = true;
        this.resetSubmitted();
        this.showAlert();
        return;
      }

     window.$A.MarketingService.AshoreAuthSignUpEvent(
         this.newAccount.name,
         this.newAccount.organization,
         this.newAccount.email,
         this.newAccount.title,
         this.newAccount.phone);

      let paramsJsonString = JSON.stringify(this.getParamsAsObject());

      let response = window.$A.UserService.Register(
          this.newAccount.organization,
          this.newAccount.name,
          this.newAccount.email,
          this.newAccount.password,
          this.formatPhoneForAPI(this.newAccount.phone),
          this.newAccount.title,
          moment.tz.zone(moment.tz.guess()).name,
          this.code,
          paramsJsonString
      );



      if (response.message !== undefined) {
        this.showDismissibleAlert = true;
        this.resetSubmitted();
        this.errorMessage = response.message;
        this.showAlert();
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    validEmail: function (email) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    inputValid: function (val) {
      return val ? "has-success" : "has-error";
    },
    allFormElementsValid: function () {
      return (
          this.newAccount.organization &&
          this.newAccount.organization.length !== 0 &&
          this.newAccount.name &&
          this.newAccount.name.length !== 0 &&
          this.newAccount.phone &&
          this.newAccount.phone.length !== 0 &&
          this.newAccount.title &&
          this.newAccount.title.length !== 0 &&
          this.newAccount.password &&
          this.newAccount.password.length !== 0 &&
          this.newAccount.password_confirm &&
          this.newAccount.password_confirm.length !== 0 &&
          this.newAccount.email &&
          this.newAccount.email.length !== 0
      );
    },
    checkForm: function () {
      this.errors = [];
      this.validated = true;

      //Organization
      if (!this.newAccount.organization) {
        this.errors.push("Organization required.");
      }

      //Name
      if (!this.newAccount.name) {
        this.errors.push("Name required.");
      }

      //Title
      if (!this.newAccount.title) {
        this.errors.push("Title required.");
      }

      //Email
      if (!this.newAccount.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.newAccount.email)) {
        this.errors.push('Please enter a valid email.');
      }

      //Phone
      if (!this.newAccount.phone || this.newAccount.phone.trim() === '') {
        this.errors.push("Phone required.");
      } else if (!this.validatePhoneNumber(this.newAccount.phone)) {
        this.errors.push('Please enter a valid phone number for the selected country.');
      }


      //Password
      if (!this.newAccount.password) {
        this.errors.push('Password required.');
      }

      // Password Confirm
      if (!this.newAccount.password_confirm) {
        this.errors.push('Confirm password required.');
      }

      // Password vs Password Confirm
      if (this.newAccount.password !== this.newAccount.password_confirm) {
        this.errors.push('Password does not match.');
      } else {
        this.password_match = true;
      }

      //Terms of service
      if (!this.newAccount.use_terms_accepted) {
        this.errors.push("Terms of Use required.");
      }

      //Terms of service
      if (!this.newAccount.data_terms_accepted) {
        this.errors.push("Privacy Policy Terms required.");
      }

      //Errors
      if (!this.errors.length) {
        this.submited = true;
        return true;
      }
    },
    validateOnBlur() {
      // Validate phone number when user clicks away
      if (this.newAccount.phone && this.newAccount.phone.trim() !== '') {
        this.validated = true;
        this.checkForm();
      }
    },
  },
  computed: {
    accountLogo: function () {
      return getAccountLogo();
    }
  },
  beforeMount() {
    let query = this.$route.query;

    if (this.$route.path.indexOf("appsumo") !== -1) {
      this.isCodeSignup = 1;
      this.affiliate = 1;
      this.phone = "000.000.0000";
      this.signupH1 = "AppSumo";
      this.codePlaceHolder = "Your AppSumo Code";
      this.signupHeadline = "Create Your New Account";
      this.code = query.c.toLowerCase();
    } else if (query.c !== undefined) {
      this.isCodeSignup = 1;
      this.code = query.c.toLowerCase();
    }
  },
  mounted() {
    let self = this;
    
    this.$root.$on("alert::user", (message, type, timeout) => {
      self.errorMessage = message;
      self.variant = type;
      self.showDismissibleAlert = true;
      self.showAlert();
    });
  },
};
</script>

<template>

  <form class="signup-page registration-page" role="form" @submit="checkForm()">
    <div class="container-fluid">
      <div class="row">
        <!-- Sidebar -->
        <div class="col-lg-4 col-md-5 m-auto">
          <div class="generic-sidebar-auth text-center">
            <div v-if="accountLogo" class="ashore-logo-registration">
                <img :src="accountLogo" class="account-logo"/>
              <img
                  v-show="signupH1 !== ''"
                  class="partner-image"
                  src="/img/appsumo.png"
                  alt="AppSumo"
              />
            </div>
            <div class="register-header-text my-4">{{ signupHeadline }}</div>
            <div class="form-group">
              <a  @click="ssoClicked('google')">
                <img src="/img/google-login-btn.svg" class="svg-btns"/>
              </a>
            </div>
            <div class="form-group">
              <a  @click="ssoClicked('microsoft')">
                <img src="/img/ms-login-btn.svg" class="svg-btns"/>
              </a>
            </div>
            <div class="auth-or-container form-group">
              <div class="auth-or">Or</div>
              <hr class="auth-or-line"/>
            </div>

            <div
                :class="['form-group', inputValid(newAccount.organization)]"
                v-show="false"
            >
              <input
                  type="text"
                  name="organization"
                  autocomplete="new-password"
                  class="form-control"
                  placeholder="Your Organization"
                  v-model="newAccount.organization"
              />
            </div>
            <label class="auth-sub-text d-flex">Your Name</label>
            <div cy-data="name"
                 :class="['form-group', inputValid(newAccount.name)]">
              <input
                  type="text"
                  name="name"
                  autocomplete="new-password"
                  :class="'form-control ' + (validated ? (newAccount.name !== '' ? ' is-valid': ' is-invalid') : ' auth-input')"
                  v-model="newAccount.name"
              />
            </div>
            <label class="auth-sub-text d-flex">Your Email</label>
            <div cy-data="email" class="form-group">
              <input
                  type="text"
                  name="email"
                  autocomplete="new-password"
                  :class="'form-control ' + (validated ? (validEmail(newAccount.email) ? ' is-valid': ' is-invalid') : ' auth-input')"
                  v-model="newAccount.email"
              />
            </div>
            <label class="auth-sub-text d-flex">Your Phone Number</label>
            <div
                cy-data="phone"
                v-show="newAccount.phone !== '000.000.0000'"
                class="form-group phone-input-container"
            >
              <VuePhoneNumberInput
                  :value="phoneData ? phoneData.phoneNumber : ''" 
                  @update="updatePhoneNumber"
                  @blur="validateOnBlur"
                  :default-country-code="countryCode"
                  :no-use-browser-locale="false"
                  :color="'#019256'"
                  :error="validated && !validatePhoneNumber(newAccount.phone)"
                  :error-color="'#dc3545'"
                  :valid-color="'#28a745'"
                  :required="false"
                  :show-code-on-list="true"
                  :key="'phone-input'"
                  :border-radius="6"
                  :text-font-family="'Open Sans'"
                  :text-font-size="14"
                  :text-font-weight="400"
                  :preferred-countries="['US', 'CA', 'GB']"
              />
              <div v-if="validated && !validatePhoneNumber(newAccount.phone)" class="phone-error-message">
                Please enter a valid phone number for the selected country
              </div>
            </div>
            <label class="auth-sub-text d-flex">Your Password</label>
            <div
                cy-data="password"
                :class="[
                  'form-group',
                  inputValid(newAccount.password && newAccount.password_confirm),
                ]"
            >
              <input
                  type="password"
                  name="password"
                  autocomplete="new-password"
                  :class="'form-control ' + (validated ? (newAccount.password !== '' ? ' is-valid': ' is-invalid') : ' auth-input')"
                  v-model="newAccount.password"
              />
            </div>
            <label class="auth-sub-text d-flex">Confirm Password</label>
            <div
                cy-data="password_confirm"
                :class="[
                  'form-group',
                  inputValid(newAccount.password_confirm === newAccount.password),
                ]"
            >
              <input
                  type="password"
                  name="password_confirm"
                  autocomplete="new-password"
                  :class="'form-control ' + (validated ? (newAccount.password_confirm !== '' && (newAccount.password_confirm === newAccount.password) ? ' is-valid': ' is-invalid') : ' auth-input')"
                  v-model="newAccount.password_confirm"
                  v-on:keyup.enter="SignUp()"
              />
            </div>
            <div :class="['form-group']" v-show="isCodeSignup === 1">
              <input
                  type="text"
                  autocomplete="Code"
                  class="form-control auth-input auth-sub-text"
                  name="code"
                  :placeholder="codePlaceHolder"
                  v-model="code"
              />
            </div>
            <div class="form-group" v-show="!password_match && validated">
              <span>Passwords do not match.</span>
            </div>

            <div
                id="terms-container"
                class="d-flex flex-wrap"
            >
              <b-form-checkbox
                  id="use_terms_checkbox"
                  v-model="newAccount.use_terms_accepted"
                  name="use_terms_checkbox"
              >
                <label class="font-weight-normal terms-style" style="text-align: start"> Yes, I have read and consent
                  to the
                  <a href="https://ashoreapp.com/terms-of-use/" target="_blank" style="color:#019256">
                    Terms of Use
                  </a> and
                  <a href="https://ashoreapp.com/terms-of-service/" target="_blank" style="color:#019256">
                    Terms of Service
                  </a>.
                </label>
              </b-form-checkbox>
              <b-form-checkbox
                  id="data_terms_checkbox"
                  v-model="newAccount.data_terms_accepted"
                  class="font-weight-normal"
                  name="data_terms_checkbox"
              >
                <label class="font-weight-normal terms-style" style="text-align: start">
                  Yes, I have read and consent to the use of my data as described in Ashore's <a
                    href="https://ashoreapp.com/privacy/" target="_blank" style="color:#019256">Privacy Policy</a>.
                </label>
              </b-form-checkbox>
            </div>
            <b-button :disabled="!newAccount.use_terms_accepted || !newAccount.data_terms_accepted"
                      data-cy="signUpButton"
                      @click="SignUp()"
                      id="signup_button"
                      class="auth-green-btn button w-100 mt-4"
            >
              <b-spinner
                  variant="success"
                  v-show="working"
                  type="grow"
                  small
              ></b-spinner>
              Create Your Account
            </b-button>
            <b-alert
                :show="dismissCountDown"
                dismissible
                :variant="variant"
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChanged"
                style="margin-top: 25px"
                cy-data="alertErrorSignUp"
            >
              <!-- Errors List -->
              <p v-if="errors.length">
              <ul>
                <li class="col-12 text-left" v-for="(errorMessage, index) in errors" :key="errorMessage.id">
                  {{ errorMessage }}
                </li>
              </ul>
              </p>
              <b-progress
                  variant="warning"
                  :max="dismissSecs"
                  :value="dismissCountDown"
                  height="4px"
              ></b-progress>
            </b-alert>
            <a href="/" class="white-auth-btn button w-100 mt-3">Login Instead</a>
          </div>
        </div>
      </div>
    </div>
  </form>

</template>

<style scoped>
.account-logo {
  width: 50px;
  height: 50px;
}

/* Custom phone input styling */
.phone-input-container {
  position: relative;
  margin-bottom: 20px; /* Reduce margin, error will appear below */
}

/* Override some of the library styles to match our design */
::v-deep .vue-phone-number-input {
  font-family: 'Open Sans';
  display: flex;
  border-radius: 6px;
  background: white;
}

::v-deep .vue-phone-number-input .select-country-container {
  flex: 0 0 auto;
  min-width: 120px;
}

/* Better vertical centering for input */
::v-deep .vue-phone-number-input input {
  height: 38px;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  padding: 0 13px; /* Remove vertical padding */
  background: white;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 38px; /* Match the height for vertical centering */
}

/* Fix vertical centering for input with value */
::v-deep .field.vue-input-ui.has-value .field-input {
  padding-top: 0;
}

/* Consistent styling for phone input and country selector */
::v-deep .vue-phone-number-input .field-input,
::v-deep .vue-phone-number-input input,
::v-deep .vue-phone-number-input .country-selector__input,
::v-deep .vue-phone-number-input input
 {
  border: 1px solid #B5B5B8 !important;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05) !important;
  height: 38px !important;
}

/* Style for error state */
::v-deep .vue-phone-number-input.error-phone-number input {
  border-color: #dc3545 !important;
}

::v-deep .vue-phone-number-input .field-input {
  margin-bottom: 0;
}

::v-deep .vue-phone-number-input .field-label {
  display: none;
}

::v-deep .vue-phone-number-input .country-selector {
  border-radius: 6px;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
}

::v-deep .country-selector .field-input {
  border-radius: 6px 0px 0px 6px;
  border-right: none;
}
.field.vue-input-ui .field-input

/* Better vertical centering for country selector */
::v-deep .vue-phone-number-input .country-selector__input {
  border-color: #B5B5B8;
  border-radius: 6px;
  background: white;
  height: 38px;
  padding: 0 0px 0 0px; /* Adjust padding to center vertically */
  display: flex;
  align-items: center;
}

/* Ensure country code and flag are perfectly centered */
::v-deep .country-selector__input__flag-container {
  margin: auto 0;
  display: flex;
  align-items: center;
}

/* Fix for flag positioning in country selector */
::v-deep .country-selector .field-country-flag {
  margin: auto 0;
  position: absolute;
  top: 16px;
  left: 11px;
  z-index: 1;
}

::v-deep .vue-phone-number-input .select-country-container{
  width: 104px;
}

/* Style for country selector in error state */
::v-deep .vue-phone-number-input.error-phone-number .country-selector__input {
  border-color: #dc3545 !important;
}

/* Fix for country selector vertical centering with the exact selector provided */
::v-deep .country-selector.has-value .field-input {
  padding-top: 0px;
  padding-left: 40px;
}

/* Fix dropdown positioning */
::v-deep .country-list {
  max-height: 200px;
  overflow-y: auto;
  z-index: 99;
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
}

/* Custom error message styling - position it below the input */
.phone-error-message {
  display: block;
  width: 100%;
  margin-top: 5px;
  color: #dc3545;
  font-size: 80%;
  text-align: left;
  text-overflow: ellipsis;
}

/* Apply vertical centering to all input fields to maintain consistency */
input.form-control, input.auth-input {
  height: 38px;
  line-height: 38px;
  padding-top: 0;
  padding-bottom: 0;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  text-align: left;
  position: absolute;
  bottom: -20px;
  left: 0;
}

.invalid-feedback.d-block {
  display: block;
}

/* Existing styles */
.generic-sidebar {
  height: 762px;
  overflow: hidden;
}

.generic-sidebar {
  height: auto;
}

.partner-image {
  width: 100%;
  max-width: 250px;
  margin: 25px auto;
}

.terms-style {
  opacity: 98 !important;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
}

.auth-or {
  background: white;
  width: 40px;
  left: calc(50% - 29px);
  text-align: center;
  position: absolute;
  top: -10px;
}

.auth-or-container {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}

.register-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  text-align: center;
}

.auth-green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F9F9F9;
}

.white-auth-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  padding: 9px 17px;
  background: #F9F9F9;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.auth-sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.auth-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.generic-sidebar-auth {
  background: #ffffff;
  border-radius: 10px;
  padding: 32px 40px;
}

.auth-or-line {
  height: 1px;
  background: #D1D5DB;
}

.svg-btns {
  width: -webkit-fill-available;
}


</style>