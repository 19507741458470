<script>
import { FAQS } from '../../utils/constants';

export default {
    name: "SettingFaq",
    data() {
        return {
            activeIndex: null,
            faqs: FAQS,
        };
    },
    methods: {
        toggleFAQ(index) {
            this.activeIndex = this.activeIndex === index ? null : index;
        },
    }
};
</script>

<template>
    <div class="faq-container">
        <h2 class="faq-title">Frequently Asked Questions</h2>
        <div class="faq-item" v-for="(faq, index) in faqs" :key="index" @click="toggleFAQ(index)">
            <button class="faq-question">
                {{ faq.question }}
                <img src="/img/chevron-up-light.svg" :class="activeIndex === index ? 'rotate-0' : 'rotate-180'"
                    alt="Arrow icon" />
            </button>
            <div :class="['faq-answer', { 'is-active': activeIndex === index }]">
                <p v-html="faq.answer"></p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.faq-container {
    padding: 64px 124px;
    background-color: #ffff;
}

.faq-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #111827;
    padding-bottom: 16px;
}

.faq-item {
    border-bottom: 1px solid #D5D5D8;
    padding: 24px 0;
    cursor: pointer;
}

.faq-question {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: #444444;
    text-align: left;
    background: none;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
}

.rotate-180 {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}

.rotate-0 {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
}

.faq-answer {
    max-height: 0;
    max-width: 96%;
    overflow: hidden;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #707073;
    padding-top: 0;
    transition: max-height 0.3s ease-in-out, padding-top 0.3s ease-in-out;
}

.faq-answer.is-active {
    max-height: 500px;
    padding-top: 10px;
}

::v-deep .last-para,
.faq-answer p {
    margin-bottom: 0px;
}

@media (max-width: 768px) {
    .faq-container {
        padding: 24px;
    }
}

@media (max-width: 450px) {
    .faq-title {
        font-size: 20px;
    }

    .faq-question {
        font-size: 14px;
    }

    .faq-answer {
        font-size: 12px;
    }
}
</style>
