<template>
  <div class="left-sidebar bg-white border-end border-gray-200 d-flex flex-column" :class="{
    'sidebar-open': showSidebarDrawer
  }">
    <div class="px-4 py-3 border-bottom border-gray-200">
      <div class="d-flex align-items-center justify-content-between">
        <h6 class="fw-bold mb-0 text-gray-900">Preflights</h6>
        <div class="d-flex gap-3 align-items-center">
          <button class="btn btn-link p-0" @click="addNewPreflight">
            <img src="/img/plus.svg" width="20" alt="plus-icon" />
          </button>
          <button @click="() => $emit('toggleSidebar')" class="d-flex d-lg-none mt-1 pl-3 btn btn-link p-0">
            <img src="/img/close.svg" width="20" alt="close-icon">
          </button>
        </div>
      </div>
    </div>

    <div class="px-4 py-3 border-bottom border-gray-200">
      <div class="position-relative">
        <img src="/img/search.svg" class="position-absolute start-0 top-50 translate-middle-y ml-3" width="20"
          alt="search-icon" />
        <input type="text" v-model="searchQuery" class="search-input pl-5" placeholder="Search..." />
      </div>
    </div>

    <div class="custom-scrollbar flex-1 overflow-auto h-100 d-flex flex-column gap-4 px-4 pb-3" @scroll="onScroll">
      <!-- New Preflight Section -->
      <div>
        <h6 class="category-text"></h6>
        <ul class="d-flex flex-column list-unstyled mb-0">
          <li class="position-relative">
            <div class="file-name cursor-pointer rounded-md py-2 text-decoration-none d-block text-nowrap text-truncate"
              :class="{ 'selected-file': selectedFileId === 'new-preflight' }" @click="addNewPreflight">
              New Preflight
            </div>
          </li>
        </ul>
      </div>

      <div v-if="showNoResultSection()" class="no-results-container">
        <p>No results found</p>
      </div>

      <!-- Unsaved Files Section -->
      <div v-if="filteredUnsavedFiles.length">
        <h6 class="category-text">Unsaved Files</h6>
        <ul class="d-flex flex-column list-unstyled mb-0">
          <li v-for="unsavedFile in filteredUnsavedFiles" :key="unsavedFile.fileId" class="position-relative">
            <div class="file-name cursor-pointer rounded-md py-2 text-decoration-none d-block text-nowrap text-truncate"
              :class="{
                'selected-file': selectedFileId === unsavedFile.fileId,
              }" @click="selectUnsavedFile(unsavedFile)">
              {{ unsavedFile.file.name }}
            </div>
          </li>
        </ul>
      </div>

      <!-- Existing Files Sections -->
      <div v-for="(files, category) in categorizedFiles" :key="category">
        <div v-if="files.length > 0 && category !== 'New Preflight'">
          <h6 class="category-text">{{ category }}</h6>
          <ul class="d-flex flex-column list-unstyled mb-0">
            <li v-for="file in files" :key="file.id" class="position-relative" @mouseover="hoverFile(file)"
              @mouseleave="leaveFile()">
              <div
                class="file-name cursor-pointer rounded-md my-1 text-decoration-none d-block text-nowrap text-truncate"
                :class="{
                  'selected-file': selectedFileId === file.id,
                  'file-hover list-hover': isFileHovered(file),
                }" @click="showFile(file)">
                <span class="file-text"> {{ file.fileName }} </span>
              </div>
              <div v-if="file.id && isFileHovered(file) && file.id !== 'new-preflight'" class="cursor-pointer"
                :class="{ 'list-hover': isFileHovered(file) }" @click="toggleDropdown(file.id)">
                <img src="/img/review/icons/dots-vertical.svg" width="20" alt="kebab-menu"
                  class="position-absolute end-0 top-50 mr-2 translate-middle-y" />
              </div>
              <div v-if="dropdownOpen === file.id" class="dropdown-menu">
                <ul class="mb-0">
                  <li @click="downloadFile(file)">
                    <img src="/img/download-icon.svg" alt="Download Icon" />
                    <span class="dropdown-text">Download</span>
                  </li>
                  <li @click="deleteFile(file)">
                    <img src="/img/trash-dark.svg" alt="Delete Icon" />
                    <span class="dropdown-text">Delete</span>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="loading" class="d-flex justify-content-center spinner-container">
        <div class="spinner"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'preflightSidebar',
  props: {
    unsavedFiles: {
      required: true,
      type: Array,
    },
    showSidebarDrawer: {
      required: true,
      type: Boolean,
    }
  },
  data() {
    return {
      files: [],
      selectedFileId: 'new-preflight',
      dropdownOpen: null,
      searchQuery: '',
      page: 1,
      totalPages: 1,
      loading: false,
      hoveredFileId: null,
    };
  },
  computed: {
    categorizedFiles() {
      const today = new Date().toISOString().split('T')[0];
      const yesterday = new Date(Date.now() - 864e5).toISOString().split('T')[0];
      const previous7Days = new Date(Date.now() - 7 * 864e5).toISOString().split('T')[0];
      const previous30Days = new Date(Date.now() - 30 * 864e5).toISOString().split('T')[0];

      return {
        'New Preflight': [{ id: 'new-preflight', fileName: 'New Preflight', createdAt: today }],
        Today: this.files.filter((file) => file.createdAt.startsWith(today)),
        Yesterday: this.files.filter((file) => file.createdAt.startsWith(yesterday)),
        'Previous 7 Days': this.files.filter(
          (file) => file.createdAt < yesterday && file.createdAt >= previous7Days
        ),
        'Previous 30 Days': this.files.filter(
          (file) => file.createdAt < previous7Days && file.createdAt >= previous30Days
        ),
      };
    },
    filteredUnsavedFiles() {
      return this.unsavedFiles.filter(f => f.file.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
    },
  },
  watch: {
    searchQuery: _.debounce(function (newQuery, oldQuery) {
      if (newQuery !== oldQuery) {
        this.page = 1;
        this.files = [];
        this.fetchFiles();
      }
    }, 500),
  },
  methods: {
    hoverFile(file) {
      this.hoveredFileId = file.id;
    },
    leaveFile() {
      this.hoveredFileId = null;
    },
    isFileHovered(file) {
      return this.hoveredFileId === file.id || this.selectedFileId === file.id;
    },
    showNoResultSection() {
      return !this.loading && !this.filteredUnsavedFiles.length && !this.files.length
    },
    addNewPreflight() {
      if (this.showSidebarDrawer) this.$emit('toggleSidebar')
      this.selectedFileId = 'new-preflight';
      this.$emit('renderNewPreflight');
    },
    addFileToToday(file) {
      const today = new Date().toISOString().split('T')[0];
      const newFile = { ...file, createdAt: today };
      this.files.unshift(newFile);
      this.selectFile(newFile.id)
    },
    selectUnsavedFile(file) {
      if (this.showSidebarDrawer) this.$emit('toggleSidebar')
      this.selectFile(file.fileId)
      this.$emit('showUnsavedFileInfo', file);
    },
    selectFile(fileId) {
      this.selectedFileId = fileId;
    },
    toggleDropdown(fileId) {
      this.dropdownOpen = this.dropdownOpen === fileId ? null : fileId;
    },
    async downloadFile(file) {
      if (this.showSidebarDrawer) this.$emit('toggleSidebar')
      const downloadUrl = await this.$A.PreflightService.GetPreflightDownloadUrl(file.id);
      if (downloadUrl && downloadUrl.length > 0) {
        window.open(downloadUrl, '_blank');
      } else {
        window.$A.AlertUser('No download URL found', 'error');
      }
      this.dropdownOpen = null;
    },
    async deleteFile(file) {
      try {
        // Close dropdown
        this.dropdownOpen = null;

        // Call the API to delete the preflight
        const response = await window.$A.PreflightService.DeletePreflight(file.id);

        if (response && response.success) {
          // Remove from local files array
          this.files = this.files.filter((f) => f.id !== file.id);

          // If the deleted file was selected, clear the view
          if (this.selectedFileId === file.id) {
            this.$emit('renderNewPreflight');
            this.selectedFileId = 'new-preflight';
          }

          window.$A.AlertUser('Preflight deleted successfully', 'success');
        } else {
          window.$A.AlertUser('Failed to delete preflight', 'error');
        }
      } catch (error) {
        console.error('Error deleting preflight:', error);
        window.$A.AlertUser('Error deleting preflight', 'error');
      }
    },
    closeDropdown(event) {
      const dropdownMenu = event.target.closest('.dropdown-menu');
      const dropdownToggle = event.target.closest('.position-absolute.end-0');

      if (!dropdownMenu && !dropdownToggle) {
        this.dropdownOpen = null;
      }
    },
    async fetchFiles() {
      if (this.loading) return;
      this.loading = true;

      const response = await this.$A.PreflightService.GetPreflightList({
        page: this.page,
        pageSize: 50,
        search: this.searchQuery
      });

      if (response && response.items) {
        this.files.push(...response.items);
        this.totalPages = response.totalPages;
      }

      this.loading = false;
      this.page += 1;
    },
    onScroll(event) {
      const bottom = event.target.scrollHeight === parseInt(event.target.scrollTop + event.target.clientHeight);
      if (bottom && this.page !== this.totalPages + 1) {
        this.fetchFiles();
      }
    },
    async showFile(file) {
      if (file.id === this.selectedFileId) return;
      if (file.id === 'new-preflight') {
        this.selectFile('new-preflight');
        this.$emit('renderNewPreflight');
        return;
      }

      this.selectFile(file.id);
      const preflightData = await this.$A.PreflightService.GetPreflight(file.id);
      if (preflightData) {
        this.$emit('updatePreflightSettings', preflightData);
      }
    },
  },
  async mounted() {
    document.addEventListener('click', this.closeDropdown);
    await this.fetchFiles();
    this.selectedFileId = 'new-preflight';
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeDropdown);
  },
};
</script>

<style scoped>
.left-sidebar {
  height: calc(100vh - 64px);
  width: 300px;
}

.search-input {
  background-color: #f9f9f9;
  padding: 9px 13px 9px 13px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #b5b5b8;
  box-shadow: 0px 1px 2px 0px #080b0d0d;
}

.file-name {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1b1b1c;
  padding: 8px 12px 8px 8px;
  border-radius: 6px;
}

.file-text {
  display: inline-block;
  width: 200px;
  padding-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-name:hover {
  background-color: #f0f9f4;
}

.list-hover {
  background-color: #f0f9f4;
}

.selected-file {
  background-color: #f0f9f4;
}

.file-hover .dropdown-menu {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

.category-text {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #707073;
  margin-top: 16px;
}

.dropdown-menu {
  display: block;
  position: absolute;
  border-radius: 6px;
  padding: 8px;
  box-shadow: 0px 0px 0px 1px #0000000d, 0px 4px 6px -2px #0000000d,
    0px 10px 15px -3px #0000001a !important;
  z-index: 1000;
  width: 100%;
  max-width: 250px;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dropdown-menu li img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.dropdown-menu li:hover {
  background-color: #f5f5f5;
}

.dropdown-text {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #3e3e40;
}

.no-results-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  color: #6b7280;
  font-size: 16px;
  font-weight: 600;
}

.spinner-container {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@media(max-width : 992px) {
  .left-sidebar {
    position: fixed;
    width: 100%;
    top: 100%;
    z-index: 1000;
    height: 100vh;
    transition: all ease-in-out 300ms;
  }

  .sidebar-open {
    top: 0px;
  }

  .search-input {
    min-width: 100%;
  }
}

@media (min-width: 992px) {
  .search-input {
    min-width: 252px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
