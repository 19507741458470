<template>
  <div>
    <navigation />
    <div class="mt-50 col-md-12 billing-section">
      <pricePlan v-if="!subscriptionState.isLifeTime" />
      <productCodeRedemption v-if="!!subscriptionState.isLifeTime" />
    </div>
<!--    <copyright />-->
  </div>
</template>

<script>
import pricePlan from "../components/settings/setting-pricePlan.vue";
import productCodeRedemption from "../components/settings/setting-productCodeRedemption.vue";

export default {
  components: {
    pricePlan,
    productCodeRedemption,
  },
  data() {
    return {
      user: this.$A.Auth,
    };
  },
  computed: {
    subscriptionState: function() {
      return _.get(this.user, 'account.subscriptionState', {});
    }
  },
  mounted() {
    if (this.$route.path.includes('cancel')) {
      this.$router.replace('/billing');
    }
  }
};
</script>

<style scoped>
.button-gray {
  min-width: 250px;
}

@media (max-width: 990px) {
  .billing-section {
    margin-top: 0px
  }
}
</style>
