<template>
  <section class="preflight-section">
    <div class="content">
      <h1 class="heading">
        Get <span class="highlight">Print-Ready PDFs</span> in Seconds
      </h1>
      <p>
        Upload your PDFs and magically transform them into print-ready files in
        seconds with Ashore Preflights, now in beta.
      </p>
      <ul class="features">
        <li>
          <img class="mr-2" src="img/check-circle.svg" alt="check-circle" />
          Resize to the desired dimensions and bleed
        </li>
        <li>
          <img class="mr-2" src="img/check-circle.svg" alt="check-circle" />
          Magically extend images to bleed
        </li>
        <li>
          <img class="mr-2" src="img/check-circle.svg" alt="check-circle" />
          Rasterize PDFs at 300 DPI in your color profile
        </li>
        <li>
          <img class="mr-2" src="img/check-circle.svg" alt="check-circle" />
          Returns your print-ready PDF
        </li>
      </ul>
      <div class="actions">
        <button class="upgrade-button" @click="navigateToBilling">
          Upgrade to Premium to Access
        </button>
        <button class="talk-button" @click="navigateToContact">Talk to Sales</button>
      </div>
    </div>
    <div class="image-container">
      <img src="/img/preflights-img.png" alt="Man printing PDF files" class="feature-image" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'nonPremiumPreflightScreen',
  methods: {
    navigateToBilling() {
      this.$router.push('/billing');
    },
    navigateToContact() {
      window.open('https://ashoreapp.com/contact', '_blank');
    }
  },
};
</script>

<style scoped>
.preflight-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  flex-wrap: wrap;
}

.heading {
  font-size: 48px;
  font-weight: 800;
  line-height: 48px;
  color: #1b1b1c;
}

.content {
  width: 50%;
  padding-left: 96px;
}

.highlight {
  color: #019256;
}

p {
  padding-top: 8px;
  margin-bottom: 16px;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #374151;
}

.features {
  list-style: none;
  padding: 0;
  margin-bottom: 48px;
}

.features li {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #575759;
}

.actions {
  display: flex;
  gap: 1rem;
}

.upgrade-button {
  background-color: #019256;
  box-shadow: 0px 1px 2px 0px #080b0d0d;
  padding: 13px 25px;
  border-radius: 6px;
  border: none;
  color: #f9f9f9;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.upgrade-button:hover {
  background-color: #017b46;
}

.talk-button {
  padding: 13px 25px;
  border-radius: 6px;
  background-color: #f9f9f9;
  color: #3e3e40;
  font-size: 16px;
  border: 1px solid #b5b5b8;
  transition: background-color 0.3s ease;
}

.talk-button:hover {
  background-color: #e0e0e0;
}

.image-container {
  width: 45%;
}

.feature-image {
  width: 100%;
  height: calc(100vh - 64px);
  object-fit: cover;
  object-position: top;
}

@media (max-width: 1200px) {
  .preflight-section {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .content {
    width: 60%;
    padding-left: 0;
    text-align: center;
  }

  .image-container {
    width: 100%;
    margin-top: 20px;
  }

  .feature-image {
    height: auto;
    max-width: 100%;
  }
}

@media (max-width: 768px) {

  .heading {
    font-size: 36px;
    text-align: center;
  }

  .content {
    width: 100%;
    padding-left: 0;
  }

  .features li {
    font-size: 14px;
  }

  .actions {
    flex-direction: column;
    gap: 1rem;
  }

  .upgrade-button,
  .talk-button {
    width: 100%;
    padding: 12px;
  }

  .image-container {
    width: 100%;
  }
}
</style>
